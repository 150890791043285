import Lottie from 'react-lottie'
import { FC, memo } from 'react';

interface LottieImportProps {
  file: any,
  loop?: boolean,
  speed?: number,
  autoplay?: boolean,
  height?: number | "auto",
  width?: number | "auto",
  isStopped?: boolean,
  isPaused?: boolean
}

const LottieImport: FC<LottieImportProps> = ({ file, loop, speed = 1, autoplay, height = 100, width = 100, isStopped, isPaused }) => {
  const defaultOptions = {
    loop: loop,
    animationData: file,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return <div className={'my-4'}>
    <Lottie options={defaultOptions}
      speed={speed}
      autoplay={autoplay}
      height={height}
      width={width}
      isStopped={isStopped}
      isPaused={isPaused}
    />
  </div>
};

export default memo(LottieImport)