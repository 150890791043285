import ContentLoader from 'react-content-loader'
import LottieImport from '../LottieFiles/Lottie';
import AnimationFiles from '../LottieFiles/AnimationFiles';

export const CardLoader = () =>
  <ContentLoader
    height={200}
    width="100%"
    speed={0.5}
    primary-color="#f3f3f3"
    secondary-color="#ecebeb"
  >
    <rect x="9" y="7" rx="5" ry="5" width="390" height="150" />
    <rect x="9" y="170" rx="5" ry="5" width="200" height="15" />
    <rect x="8" y="200" rx="5" ry="5" width="274" height="15" />
  </ContentLoader>

export const NormalLoader = ({ size = 100 }) => <LottieImport loop={true} height={size} width={size} file={AnimationFiles.Loader} />;

export const Loader = () =>
  <div className="d-flex align-items-center justify-content-center vh-100">
    <NormalLoader />
  </div>;

export const ListLoader = () => (
  <ContentLoader speed={1} height={130} width={400}>
    <rect x="25" y="15" rx="5" ry="5" width="220" height="10" />
    <rect x="25" y="45" rx="5" ry="5" width="220" height="10" />
    <rect x="25" y="75" rx="5" ry="5" width="220" height="10" />
    <rect x="25" y="105" rx="5" ry="5" width="220" height="10" />
  </ContentLoader>
)



